import React, { useMemo, useState, useEffect } from 'react';
import { Opinion } from '../types/Opinion';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import axios from 'axios';

interface OpinionsStatsProps {
  isPublic?: boolean;
  opinions?: Opinion[];
  startDate?: Date;
  endDate?: Date;
}

export const OpinionsStats: React.FC<OpinionsStatsProps> = ({ 
  isPublic = false,
  opinions: propsOpinions,
  startDate: propsStartDate,
  endDate: propsEndDate 
}) => {
  const [opinions, setOpinions] = useState<Opinion[]>(propsOpinions || []);
  const [isLoading, setIsLoading] = useState(isPublic);
  const [error, setError] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [expandedOpinionId, setExpandedOpinionId] = useState<string | null>(null);

  useEffect(() => {
    if (isPublic) {
      const fetchOpinions = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${import.meta.env.VITE_API_URL}/statistics`);
          setOpinions(response.data);
        } catch (error) {
          console.error('Failed to load statistics:', error);
          setError('Nie udało się załadować statystyk');
        } finally {
          setIsLoading(false);
        }
      };

      fetchOpinions();
    }
  }, [isPublic]);

  const dailyStats = useMemo(() => {
    const stats = new Map<string, number>();
    
    opinions.forEach(opinion => {
      const date = format(new Date(opinion.rated_at), 'yyyy-MM-dd');
      stats.set(date, (stats.get(date) || 0) + 1);
    });
    
    // Sortuj daty i przekształć do formatu dla wykresu
    return Array.from(stats.entries())
      .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
      .map(([date, count]) => ({
        date: format(new Date(date), 'd MMMM', { locale: pl }),
        ilość: count
      }));
  }, [opinions]);

  const issueTypeStats = useMemo(() => {
    const stats = new Map<string, number>();
    
    opinions.forEach(opinion => {
      const type = opinion.issue_type || 'Nieokreślony';
      stats.set(type, (stats.get(type) || 0) + 1);
    });
    
    return Array.from(stats.entries())
      .map(([type, count]) => ({
        type,
        count
      }))
      .sort((a, b) => b.count - a.count);
  }, [opinions]);

  const opinionsByType = useMemo(() => {
    if (!selectedType) return [];
    return opinions.filter(op => (op.issue_type || 'Nieokreślony') === selectedType);
  }, [selectedType, opinions]);

  const handleOpinionClick = (opinion: Opinion) => {
    const id = `${opinion.network_id}-${opinion.rated_at}`;
    setExpandedOpinionId(expandedOpinionId === id ? null : id);
  };

  const summary = useMemo(() => {
    const total = opinions.length;
    const withIssueType = opinions.filter(op => op.issue_type).length;
    const withQAStatus = opinions.filter(op => op.qa_status).length;
    
    return {
      total,
      withIssueType,
      withQAStatus,
      withoutIssueType: total - withIssueType,
      withoutQAStatus: total - withQAStatus,
      percentageWithIssueType: ((withIssueType / total) * 100).toFixed(1),
      percentageWithQAStatus: ((withQAStatus / total) * 100).toFixed(1)
    };
  }, [opinions]);

  if (isLoading) {
    return (
      <div className="container">
        <div className="box has-text-centered">
          <p>Ładowanie statystyk...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="box has-text-centered has-text-danger">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="title is-4 mb-4">Statystyki opinii</h2>
      
      <div className="box mb-6">
        <h3 className="title is-5 mb-3">Dzienny rozkład opinii</h3>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <LineChart
              data={dailyStats}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                angle={-45}
                textAnchor="end"
                height={80}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="ilość"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="box">
        <h3 className="title is-5 mb-3">Statystyki typów problemów</h3>
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Typ problemu</th>
              <th>Ilość</th>
              <th>Procent</th>
            </tr>
          </thead>
          <tbody>
            {issueTypeStats.map(({ type, count }) => (
              <tr 
                key={type}
                onClick={() => setSelectedType(selectedType === type ? null : type)}
                style={{ cursor: 'pointer' }}
                className={selectedType === type ? 'is-selected' : ''}
              >
                <td>{type}</td>
                <td>{count}</td>
                <td>
                  {((count / opinions.length) * 100).toFixed(1)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {selectedType && (
          <div className="mt-4">
            <h4 className="title is-6">Opinie dla typu: {selectedType}</h4>
            <table className="table is-fullwidth is-hoverable">
              <thead>
                <tr>
                  <th>Nr sieci</th>
                  <th>Data</th>
                  <th>Komentarz</th>
                  <th>Status QA</th>
                </tr>
              </thead>
              <tbody>
                {opinionsByType.map((opinion) => {
                  const id = `${opinion.network_id}-${opinion.rated_at}`;
                  const isExpanded = expandedOpinionId === id;
                  return (
                    <tr 
                      key={id}
                      onClick={() => handleOpinionClick(opinion)}
                      style={{ cursor: 'pointer' }}
                      className={isExpanded ? 'is-selected' : ''}
                    >
                      <td>{opinion.network_id}</td>
                      <td>
                        {new Date(opinion.rated_at).toLocaleDateString('pl-PL', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </td>
                      <td>
                        <div className={`opinion-content ${isExpanded ? 'is-expanded' : ''}`}>
                          {opinion.rate_comment}
                        </div>
                      </td>
                      <td>{opinion.qa_status || 'Brak'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="box mt-6">
        <h3 className="title is-5 mb-3">Podsumowanie</h3>
        <div className="columns is-multiline">
          <div className="column is-6">
            <div className="notification is-info">
              <p className="has-text-weight-bold">Łączna liczba opinii: {summary.total}</p>
            </div>
          </div>
          <div className="column is-6">
            <div className="notification is-primary">
              <p className="has-text-weight-bold">Opinie z przypisanym typem:</p>
              <p>{summary.withIssueType} ({summary.percentageWithIssueType}%)</p>
            </div>
          </div>
          <div className="column is-6">
            <div className="notification is-warning">
              <p className="has-text-weight-bold">Opinie bez typu:</p>
              <p>{summary.withoutIssueType} ({(100 - Number(summary.percentageWithIssueType)).toFixed(1)}%)</p>
            </div>
          </div>
          <div className="column is-6">
            <div className="notification is-success">
              <p className="has-text-weight-bold">Opinie ze statusem QA:</p>
              <p>{summary.withQAStatus} ({summary.percentageWithQAStatus}%)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 